<template>
  <div id="chat">
      
    <div id="header-chat" class="row" style="margin-bottom: 0.25em;">
      <div class="d-none d-lg-block col-lg-6">
          <strong>Post links to your videos here</strong> <small>(They will stay for 30 days)</small>  
      </div>
      <div class="col-6 d-lg-none">
        <div class="row">
          <div class="col-6">
            <button id="showLinks" class="btn btn-primary w-100" @click="showLinks()">Links</button>
          </div>
          <div class="col-6">
            <button id="showChat" class="btn btn-primary w-100" @click="showChat()">Chat</button>
          </div>
        </div>
      </div> 
      <div class="col-6 col-lg-6">
        <select id="roomSelector" v-model="roomID" class="form-select" @change="changeRoom($event.target.value)">
          <option value="WORLD">WORLD</option>

          <option value="EUROPE">EUROPE</option>
            <option value="Benelux">- Benelux</option>
            <option value="France">- France</option>
            <option value="Germany">- Germany</option>
            <option value="Italy">- Italy</option>
            <option value="Spain">- Spain</option>
            <option value="Poland">- Poland</option>
            <option value="United Kingdom">- United Kingdom</option>

          <option value="NORTH AMERICA">NORTH AMERICA</option>
            <option value="Canada">- Canada</option>
            <option value="USA">- USA</option>
            <option value="Mexico">- Mexico</option>

          <option value="SOUTH AMERICA">SOUTH AMERICA</option>
            <option value="Argentina">- Argentina</option>
            <option value="Brazil">- Brazil</option>
            <option value="Chile">- Chile</option>
            <option value="Colombia">- Colombia</option>
            <option value="Ecuador">- Ecuador</option>
            <option value="Peru">- Peru</option>
            <option value="Venezuela">- Venezuela</option>

          <option value="ASIA">ASIA</option>
            <option value="Indonesia">- Indonesia</option>
            <option value="Iran">- Iran</option>
            <option value="India">- India</option>
            <option value="Israel">- Israel</option>
            <option value="Japan">- Japan</option>
            <option value="Malaysia">- Malaysia</option>
            <option value="Philippines">- Philippines</option>
            <option value="South Korea">- South Korea</option>
            <option value="Turkey">- Turkey</option>
            <option value="Thailand">- Thailand</option>
            <option value="UEA">- UAE</option>

          <option value="AFRICA">AFRICA</option>
            <option value="Cape Verde">- Cape Verde</option>
            <option value="Morocco">- Morocco</option>
            <option value="South Africa">- South Africa</option>

          <option value="OCEANIA">OCEANIA</option>
            <option value="Australia">- Australia</option>
            <option value="New Zealand">- New Zealand</option>


          <!-- Add more options as needed -->
        </select> 
      </div>
    </div>

    <div id="panels" class="row">

        <div id="links-panel" class="col-lg-6">
          <div id="links">
            <ul> 
              <li v-for="(link, index) in links" :key="index">
                <div class="row">
                  <div class="col-8"> 
                    <img v-if="link.avatar" :src="link.avatar" alt="User Avatar" class="chat-avatar" />
                    <strong v-if="link.userID">
                      <a :href="'/profiles/show/' + link.userID">{{ link.username }}</a>
                    </strong>
                    <strong v-else>
                      {{ link.username }} : {{ link.message }}
                    </strong>
                  </div>
                  <div class="col-4 text-end">
                    ({{ formatTimestamp(link.timestamp) }})
                  </div>
                  <div class="col-12">
                    <a :href="link.URL" target="_blank">{{ link.URL }}</a>
                  </div>
                </div>
              </li>
            </ul>  
          </div>
          <div id="link-input" class="input-container">
            <!-- Link input -->
            <!--input v-model="newLinkTitle" @keyup.enter="sendLink" placeholder="Title" /-->
            <input v-model="newLink" @keyup.enter="sendLink" placeholder="Paste your link here" />
            
            <!-- Send button for mobile/desktop -->
            <button type="button" id="send-link-button" @click="sendLink" @touchstart="sendLink">Add</button>
          </div>
        </div>

        <div id="chat-panel" class="col-lg-6">
          <div id="messages">
            <ul> 
              <li v-for="(message, index) in messages" :key="index">
                <img v-if="message.avatar" :src="message.avatar" alt="User Avatar" class="chat-avatar" />
                <strong v-if="message.userID">
                  <a :href="'/profiles/show/' + message.userID">{{ message.username }}</a>
                </strong>
                <strong v-else>
                  {{ message.username }}
                </strong>&nbsp; 
                <span v-html="formatMessageWithLinks(message.message)"></span> 
               ({{ formatTimestamp(message.timestamp) }})
              </li>
            </ul>  
          </div>
          <div id="message-input" class="input-container">
            <!-- Message input -->
            <input id="chat-input" v-model="newMessage" @keyup.enter="sendMessage" @input="onMessageInput" placeholder="Type your message" />

            <!-- Display dropdown when typing '@' -->
            <ul v-if="showUserList" class="dropdown-menu">
              <li v-for="user in filteredUsers" :key="user.id" @click="selectUser(user.username)">
                {{ user.username }}
              </li>
            </ul>

            <!-- Send button for mobile/desktop -->
            <button type="button" id="send-message-button" @click="sendMessage" @touchstart="sendMessage">Send</button>
          </div>
        </div>

      </div>
  </div> 

  <div class="toast" id="liveToast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" style="position: absolute; top: 3rem; right: 1rem; opacity: 1;">
      <div class="toast-header">
          <strong class="me-auto" id="toast-origin">$ToastOrigin</strong>
          <small id="toast-title" style="color: $ColorToastTitle;">$ToastTitle</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body" id="toast-message" style="background-color: rgba(255, 255, 255, 1); opacity: 1;">
          $ToastMessage
      </div>
  </div>

 
</template>
 
 

<script>
// Import Socket.IO client
import { io } from 'socket.io-client';
import dayjs from 'dayjs';  // Import dayjs
import relativeTime from 'dayjs/plugin/relativeTime';
import Cookies from 'js-cookie';

// Extend dayjs to use the relativeTime plugin
dayjs.extend(relativeTime);

export default {
  data() {
    return {
      newMessage: '',
      newLink: '',
      newLinkTitle: '',
      messages: [],
      links: [],
      blockedSites: [], 
      socket: null,
      roomID: 'WORLD', 
      isSending: false, // to avoid multiple sends
      supportsTouch: 'ontouchstart' in window || navigator.maxTouchPoints > 0, 
      userId: null,
      username: null,
      avatar: null,
      users: [], // The list of all users, fetched from backend or available locally
      filteredUsers: [], // Filtered user list to display
      showUserList: false, // Controls visibility of the user list dropdown
      atMentionDetected: false, // Tracks if @ has been typed
    };
  },
  methods: {
    showWelcomeMessage() {
      const welcomeMessageCookie = Cookies.get('welcomeMessageShown');
      if (!welcomeMessageCookie) {
        const welcomeMessage = {
          message: 'This chat is brought to you by Unlabelled https://unlabelled.store',  
          roomID: this.roomID,
          userID: 0, // You can set a system ID for this if needed
          username: 'Ad',
          timestamp: new Date().toISOString(),
          avatar: '/assets/Avatar/Avatar-UNL-Black-20px.jpg' // Or provide a default system avatar
        };
        
        this.messages.push(welcomeMessage);
        Cookies.set('welcomeMessageShown', 'true', { expires: 1, path: '/' }); // Expires in 1 day
      }
    },
    fetchUserData() {
      return fetch('/api/user/') // Replace with your actual API endpoint
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          return response.json();
        })
        .then(data => {
          this.userId = data.id;       // Store user ID
          this.username = data.username;   // Store username
          this.avatar = data.avatar;
          this.socket.emit('registerUser', { userID: data.id, username: data.username });
          return data;
        })
        .catch(error => {
          console.error('Error in fetching user data:', error);
        });
    },
    fetchUsers(){
      fetch('/api/users') // Adjust endpoint as needed
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch users');
          }
          return response.json();
        })
        .then(data => {
          this.users = data; // Store users in the data property
          console.log(this.users);
        })
        .catch(error => {
          console.error('Error in fetching user data:', error);
        });
    },
    fetchConnectedUsers() {
      // Emit an event to get connected users from the server
      this.socket.emit('fetchConnectedUsers');

      // Listen for the response containing connected users
      this.socket.on('connectedUsersList', (users) => {
          this.users = users; // Populate the users array
          console.log(this.users);
      });
    },
    formatMessageWithLinks(message) {
      // Regular expression to match URLs
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      
      // Replace URLs in the message with clickable links
      return message.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
    },
    // Function to convert text-based emoticons to their Unicode emoji counterparts
    convertEmoticonsToEmoji(text) {
      const emoticonMap = {
        ':)': '\u{1F60A}',  // 😊 Smiling Face
        ':(': '\u{1F641}',  // ☹️ Frowning Face
        ':/': '\u{1F615}',   // 😕 Confused Face
        ':D': '\u{1F603}',  // 😄 Grinning Face with Big Eyes
        ';)': '\u{1F609}',  // 😉 Winking Face
        ':P': '\u{1F61B}',  // 😛 Face with Tongue
        ':O': '\u{1F62E}',  // 😮 Face with Open Mouth
        'B)': '\u{1F60E}',  // 😎 Smiling Face with Sunglasses
        'XD': '\u{1F606}',   // 😆 Laughing Face 
        '<3': '\u{1F499}'   // 💙 Blue Heart
        // Add more emoticon-to-emoji mappings as needed
      };
 
      // Use a regular expression to find and replace the emoticons with their corresponding emojis
      return text.replace(/:\)|:\(|:D|;\)|:P|:O|B\)|XD|:\/|<3/g, match => emoticonMap[match] || match);
    },
    // Track user input
    onMessageInput(event) {
      const inputValue = event.target.value;
      
      // Check if '@' is typed
      if (inputValue.includes('@')) {
        this.atMentionDetected = true;

        // Extract the username being typed after '@'
        const atIndex = inputValue.lastIndexOf('@');
        const typedUsername = inputValue.substring(atIndex + 1).toLowerCase();

        if (typedUsername) {
          // Filter users whose usernames match the typed input
          this.filteredUsers = this.users.filter(user => 
            user.username.toLowerCase().startsWith(typedUsername)
          );
          this.showUserList = true;
        }
      } else {
        this.atMentionDetected = false;
        this.showUserList = false;
      }
    },
    // Select a user from the list
    selectUser(username) {
      const atIndex = this.newMessage.lastIndexOf('@');
      // Replace the typed text after '@' with the selected username
      this.newMessage = this.newMessage.substring(0, atIndex + 1) + username + ' ';
      this.showUserList = false; // Hide the user list
    },
    mentionUser(userID, toastMessage) {
        const toastData = {
            origin: 'Chat',
            title: `${this.username} mentioned you`,
            color: 'black',
            message: toastMessage,
            type: 'success'
        }

        //console.log('user ID' + userID);
      // Emit the toast notification to the server
        this.socket.emit('sendToast', { userID, toast: toastData });
    },
    checkMentionedUser(mentionedUser) {
      // Check if the mentioned user exists in the current users list
      const user = this.users.find(userID => userID === mentionedUser);
      if (user) {
        // If the user exists, mention them
        console.log("no fetch necessary");
      } else {
        // If the user doesn't exist, refetch the connected users
        console.warn(`User ${mentionedUser} not found, refetching connected users...`);
        this.fetchConnectedUsers(); // Refetch connected users
      }
    },
    detectMention(messageText) {
        const mentionPattern = /@(\w+)/g; // Simple regex to find mentions, adjust as needed
        const mentionedUsers = [];

        let match;

        while ((match = mentionPattern.exec(messageText)) !== null) {
            mentionedUsers.push(match[1]); // Add the username (or userID if you change the pattern)
        }

        console.log(mentionedUsers);

        return mentionedUsers.length > 0 ? mentionedUsers : null; // Return an array of mentions or null
    },
    findUserByUsername(username) {
        return this.users.find(user => user.username === username); // Assuming 'users' is an array of user objects
    },
    sendMessage() {
      if (this.isSending) return;

      if (!this.newMessage.trim()) {
        console.error('Message text is empty.');
        return;
      }

      let emojiMessage = this.convertEmoticonsToEmoji(this.newMessage);

      const messageData = {
        message: emojiMessage,
        roomID: this.roomID,
        userID: this.userId,
        username: this.username,
        timestamp: new Date().toISOString(),
        avatar: this.avatar
      };

      this.isSending = true; // Set flag to true to prevent multiple sends

      fetch('/chat/addMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messageData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.status === 'success') {

            const mentionedUsers = this.detectMention(emojiMessage);
            this.socket.emit('sendMessage', messageData);

            // Emit toast notifications for mentioned users
            if (mentionedUsers) {

                mentionedUsers.forEach(username => this.checkMentionedUser(username)); // Check each mentioned user

                mentionedUsers.forEach(mentionedUser => {
                    const user = this.findUserByUsername(mentionedUser); // Implement this function
                    if (user) {
                        console.log(user.id);
                        this.mentionUser(user.id, emojiMessage);
                    }
                });
            }

            this.newMessage = '';
            this.scrollToBottom();
            this.showUserList = false;

            // Scroll to top or refresh the layout after sending the message
            window.scrollTo(0, 0);  // Ensures you scroll to the top after message sent
            document.activeElement.blur(); // Closes the virtual keyboard on mobile

          } else {
            console.error(data.message);
          }
          this.isSending = false; // Reset flag after sending
        })
        .catch(error => {
          console.error('Error in fetch operation:', error);
          this.isSending = false;
        });
    },
    fetchMessages() {
      fetch('/chat/messages/' + this.roomID)
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            this.messages = data.messages;
            this.showWelcomeMessage();
            this.scrollToBottom();
          } else {
            console.error('Error fetching messages:', data.message);
          }
        })
        .catch(error => {
          console.error('Error in fetch operation:', error);
        });
    },
    sendLink() {
      if (this.isSending) return;

      // Regular expression to validate URL format
      const urlPattern = /^(https?:\/\/)?((www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/[^\s]*)?$/;

      if (!this.newLink.trim() /*|| !this.newLinkTitle.trim()*/) {
        console.error('Link text is empty.');
        return;
      }

      /* CHECK FOR VALID URL */

      if (!urlPattern.test(this.newLink.trim())) {
        console.error('Invalid URL format.');
        
        // Send system message to notify user of invalid link
        const systemMessage = {
          message: 'The link you provided is not valid.',
          roomID: this.roomID,
          userID: null, // System messages don't have a userID
          username: 'System',
          timestamp: new Date().toISOString(),
          avatar: '/assets/Avatar/Avatar-UNL-Black-20px.jpg' // No avatar for system messages
        };

        this.links.push(systemMessage);
        this.newLink = '';
        return;
      }

      /* CHECKS FOR RESTRICTED LINKS */

      const isBlocked = this.blockedSites.some(site => this.newLink.includes(site.URL));

      if (isBlocked) {
        // Send a system message indicating that the link is not allowed
        const systemMessage = {
          message: 'The link you attempted to share is not allowed.',
          roomID: this.roomID,
          userID: null, // Use a special ID for system messages
          username: 'System',
          timestamp: new Date().toISOString(),
          avatar: '/assets/Avatar/Avatar-UNL-Black-20px.jpg' // Optionally add a system avatar
        };

        this.links.push(systemMessage);
        this.newLink = ''; // Clear the link input
        this.scrollToBottom();
        return; // Exit if the link is blocked
      }
      

      /* ALL CHECKS DONE : SENDING LINK */

      // Prepend https:// if the link doesn't start with http:// or https://
      if (!this.newLink.startsWith('http://') && !this.newLink.startsWith('https://')) {
        this.newLink = 'https://' + this.newLink;
      }

      const linkData = {
        URL: this.newLink,
        //title : this.newLinkTitle,
        roomID: this.roomID,
        userID: this.userId,
        username: this.username,
        timestamp: new Date().toISOString(),
        avatar: this.avatar
      };

      this.isSending = true; // Set flag to true to prevent multiple sends

      fetch('/chat/addLink', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(linkData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.status === 'success') {
            this.socket.emit('sendLink', linkData);
            this.newLink = '';
            //this.newLinkTitle = '';
            this.scrollToBottom();

            // Scroll to top or refresh the layout after sending the message
            window.scrollTo(0, 0);  // Ensures you scroll to the top after message sent
            document.activeElement.blur(); // Closes the virtual keyboard on mobile

          } else {
            console.error(data.message);
          }
          this.isSending = false; // Reset flag after sending
        })
        .catch(error => {
          console.error('Error in fetch operation:', error);
          this.isSending = false;
        });
    },
    fetchBlockedSites() {
      try {
        fetch('/chat/blockedSites/') // Ensure you have this endpoint set up
          .then(response => response.json())
          .then(data => {
            this.blockedSites = data;
        })
      } catch (error) {
        console.error('Error fetching blocked sites:', error);
      }
    },
    fetchLinks() {
      fetch('/chat/links/' + this.roomID)
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            this.links = data.links;
            this.scrollToBottom();
          } else {
            console.error('Error fetching links:', data.link);
          }
        })
        .catch(error => {
          console.error('Error in fetch operation:', error);
        });
    },
    fetchToast() {
        fetch('/api/getToast')
            .then(response => response.json())
            .then(toastData => {
                if (toastData && toastData.toasts) {
                    // Loop through each toast in the array
                    toastData.toasts.forEach(toast => {
                        this.showNewMessageToast(
                            toast.origin,   // origin
                            toast.title,    // title
                            toast.color,    // color
                            toast.message,  // message
                            toast.type      // type
                        );
                    });
                }
            })
            .catch(error => console.error('Error fetching toast:', error));
    },
    showNewMessageToast(origin, title, color, message, type) {
        const toastEl = document.querySelector('#liveToast');  // Use the correct ID
        const toastMessage = document.getElementById('toast-message');
        const toastTitle = document.getElementById('toast-title');
        const toastOrigin = document.getElementById('toast-origin');

        // Check if toastEl and its elements are found in the DOM
        if (!toastEl || !toastMessage || !toastTitle || !toastOrigin) {
            console.log('Toast element, message, title, or origin not found.');
            return;
        }

        // Update the toast content dynamically
        toastMessage.textContent = message;
        toastTitle.textContent = title;
        toastTitle.style.color = color;
        toastOrigin.textContent = origin;

        // Initialize and show the toast
        const toast = new bootstrap.Toast(toastEl);
        toast.show();

        fetch('/api/clearToast');

    },
    showToast(dataToast) {
        const toastEl = document.querySelector('#liveToast');  // Use the correct ID
        const toastMessage = document.getElementById('toast-message');
        const toastTitle = document.getElementById('toast-title');
        const toastOrigin = document.getElementById('toast-origin');
        //const toastType = document.getElementById('toast-type');

        // Check if toastEl and its elements are found in the DOM
        if (!toastEl || !toastMessage || !toastTitle || !toastOrigin) {
            console.log('Toast element, message, title, or origin not found.');
            return;
        }

        // Update the toast content dynamically using the dataToast object
        toastOrigin.textContent = dataToast.origin;
        toastTitle.textContent = dataToast.title;
        toastMessage.textContent = dataToast.message;
        toastTitle.style.color = dataToast.color;
        //toastType.textContent = dataToast.type;
        

        // Initialize and show the toast
        const toast = new bootstrap.Toast(toastEl);
        toast.show();

        // Clear toast after showing (if you need to)
        fetch('/api/clearToast');
    },
    showChat() {
      this.$nextTick(() => {
        document.querySelector('#chat-panel').style.display = 'block'; // Use vanilla JS
        document.querySelector('#links-panel').style.display = 'none';
        this.setPanelCookie('Messages');
      });
    },
    showLinks() {
      this.$nextTick(() => {
        document.querySelector('#chat-panel').style.display = 'none';
        document.querySelector('#links-panel').style.display = 'block';
        this.setPanelCookie('Links');
      });
    },
    // Function to set the room cookie using js-cookie
    setRoomCookie(roomID) {
      Cookies.set('chatRoom', roomID, { expires: 7, path: '/' }); // Expires in 7 days
    },
    // Function to get the room cookie using js-cookie
    getRoomCookie() {
      return Cookies.get('chatRoom');
    },
    changeRoom(newRoomID) {
      this.messages = [];
      this.links = [];
      this.roomID = newRoomID;
      this.fetchLinks();
      this.fetchMessages();
      this.setRoomCookie(newRoomID);
    },
    // Function to set the room cookie using js-cookie
    setPanelCookie(panel) {
      Cookies.set('chatPanel', panel, { expires: 7, path: '/' }); // Expires in 7 days
    },
    // Function to get the room cookie using js-cookie
    getPanelCookie() {
      return Cookies.get('chatPanel'); 
    },
    scrollToBottom() {
      this.$nextTick(() => {
        
          const linksContainer = document.querySelector('#links');
          const chatContainer = document.querySelector('#messages');
      
        if (this.getPanelCookie() === 'Links'){
          linksContainer.scrollTop = linksContainer.scrollHeight;
        } 

        if (this.getPanelCookie() === 'Messages'){
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }
        
      });
    },
    // Add formatTimestamp function to format the timestamps
    formatTimestamp(timestamp) {
      return dayjs(timestamp).fromNow(); // Use dayjs to format the timestamp as "X minutes ago"
    },
    checkWidth() {
      const linksPanel = document.querySelector('#links-panel');
      const chatPanel = document.querySelector('#chat-panel');
      const windowWidth = window.innerWidth;

      if (windowWidth < 992) { // Adjust this value based on when you want to hide the links panel
        if (this.getPanelCookie() === 'Links'){  
          linksPanel.style.display = 'block';
          chatPanel.style.display = 'none'; // Show chat panel by default
        } else {  
          linksPanel.style.display = 'none';
          chatPanel.style.display = 'block'; // Show chat panel by default
        }
      } else {
        linksPanel.style.display = 'block';
        chatPanel.style.display = 'block'; // Ensure chat panel is also shown
      }

      document.documentElement.style.overflowY = 'hidden';

    },
    handleInputFocus() {
      // When the keyboard opens, restrict overflow to prevent resizing issues
      document.documentElement.style.overflowY = 'hidden';
    },
    handleInputBlur() {
      // When input focus is lost, remove the overflow restriction
      document.documentElement.style.overflowY = '';
    }, 
    addEventListeners() {
      const sendButton = document.querySelector('#send-message-button');
      if (this.supportsTouch) {
        sendButton.addEventListener('touchstart', this.sendMessage);
      } else {
        sendButton.addEventListener('click', this.sendMessage);
      }

      const sendButtonLink = document.querySelector('#send-link-button');
      if (this.supportsTouch) {
        sendButton.addEventListener('touchstart', this.sendLink);
      } else {
        sendButton.addEventListener('click', this.sendLink);
      }
    },

  },
  mounted() {

    this.socket = io('https://websocket.digitweaks.com:3000', {
      path: '/socket/',
      transport: ['websocket'],
      reconnect: true,
    }); 

    this.fetchUserData()
      .then(() => {
        this.fetchConnectedUsers();
      }); 

    this.handleInputFocus();
    const inputField = document.querySelector('input');
    inputField.addEventListener('focus', this.handleInputFocus);
    inputField.addEventListener('blur', this.handleInputBlur);
    
    this.fetchBlockedSites();

    // Retrieve the room from the cookie if it exists
    const savedRoom = this.getRoomCookie();
    if (savedRoom) {
      this.roomID = savedRoom;
    }

    this.fetchMessages();
    this.fetchLinks();
    this.fetchToast();

    // Check width on mount
    this.checkWidth();

    // Add resize event listener
    window.addEventListener('resize', this.checkWidth);

    this.socket.on('message', (data) => {
      if (data.roomID === this.roomID) {
        this.messages.push(data);
        this.scrollToBottom();
      }
    });

    this.socket.on('URL', (data) => {
      if (data.roomID === this.roomID) {
        this.links.push(data);
        this.scrollToBottom();
      }
    });

    this.socket.on('toast', (toast) => {
      this.showToast(toast); 
    });

    // Call method to add the appropriate event listeners
    this.addEventListeners();

  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }

    // Clean up event listeners when the component is destroyed
    const inputField = document.querySelector('input');
    inputField.removeEventListener('focus', this.handleInputFocus);
    inputField.removeEventListener('blur', this.handleInputBlur);

    // Clean up event listeners when the component is destroyed
    const sendButton = document.querySelector('#send-message-button');
    if (this.supportsTouch) {
      sendButton.removeEventListener('touchstart', this.sendMessage);
    } else {
      sendButton.removeEventListener('click', this.sendMessage);
    }

    const sendButtonLink = document.querySelector('#send-link-button');
    if (this.supportsTouch) {
      sendButton.removeEventListener('touchstart', this.sendLink);
    } else {
      sendButton.removeEventListener('click', this.sendLink);
    }
  }
}; 

</script>